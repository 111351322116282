import axios from 'axios';
import { execCopy } from '../utils/copy';

const generalTokenApiPath = '/v2/api/invitation/introductions';
const topicsShareTokenApiPath = '/v2/api/invitation/introductions?topic_id=';

const EmailSubject = 'AskDoctors無料会員招待のお知らせ';

const tokenElement = document.querySelector('meta[name=csrf-token]');
const client = axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': tokenElement ? tokenElement.content : null
  }
});

async function createToken(path) {
  const { data } = await client.post(path);
  return data.message;
}

function createTokenAndCopy() {
  sendGA('copyInvitation');
  execCopy({ selector: '#copymessage' });
  closeModal();
  return false;
}

async function mailInvitationWithTopic(topicId) {
  sendGA('mailInvitationWithTopic', topicId);
  const message = await createToken(topicsShareTokenApiPath + topicId);
  if (message) {
    location.href =
      'mailto:?&subject=' +
      encodeURIComponent(EmailSubject) +
      '&body=' +
      encodeURIComponent(message);
  }
}

function mailInvitation() {
  sendGA('mailInvitation', null);
  const message = document.getElementById('copymessage').value;
  if (message) {
    location.href =
      'mailto:?&subject=' +
      encodeURIComponent(EmailSubject) +
      '&body=' +
      encodeURIComponent(message);
  }
  closeModal();
}

function lineInvitation() {
  sendGA('lineInvitation', null);
  const message = document.getElementById('copymessage').value;
  if (message) {
    location.href = `https://line.me/R/share?text=${encodeURIComponent(
      message
    )}`;
  }
  closeModal();
  return false;
}

async function lineInvitationWithTopic(topicId) {
  sendGA('lineInvitationWithTopic', topicId);
  const message = await createToken(topicsShareTokenApiPath + topicId);
  if (message) {
    location.href = `https://line.me/R/share?text=${encodeURIComponent(
      message
    )}`;
  }
  return false;
}

function sendGA(type, topicId) {
  sendGAEvent('invitation-button', type, topicId, type, location.href);
}

async function openModal(e) {
  e.preventDefault();
  document.getElementById('copymessage').value = await createToken(
    generalTokenApiPath
  );
  document.getElementById('campaigns-dialog').style.display = 'block';
}

function closeModal() {
  document.getElementById('campaigns-dialog').style.display = 'none';
  return false;
}

window.copyInvitation = createTokenAndCopy;
window.mailInvitation = mailInvitation;
window.mailInvitationWithTopic = mailInvitationWithTopic;
window.lineInvitation = lineInvitation;
window.closeModal = closeModal;
window.lineInvitationWithTopic = lineInvitationWithTopic;
window.openModal = openModal;
